.swiper {
    width: 100%;
    height: 100vh;
    position: relative;
   
}
.mySwiper{
    z-index: -1;
}
.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    
}

