/* width */
.sc::-webkit-scrollbar {
    width: 100%;
    height: 10px;
}

/* Track */
.sc::-webkit-scrollbar-track {
    background: black;
}

/* Handle */
.sc::-webkit-scrollbar-thumb {
    background: #e20613;
    width: 20px;
}

/* Handle on hover */
.sc::-webkit-scrollbar-thumb:hover {
    background: #555;
}